<template>
  <base-dialog v-model="dialog" title="Configuração da cron" :max-width="max_width">
    <v-row class="mx-1">
      <v-col cols="12">
        <VueCronEditorVuetify v-model="cronExpression" locale="pt"></VueCronEditorVuetify>
        Cron: {{ cronExpression }}
        <v-row>
          <v-col cols="6">
            <v-row justify="start">
              <v-col cols="auto">
                <mf-button label="Voltar" outlined color="error" @click="closeDialog"></mf-button>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-row justify="end">
              <v-col cols="auto">
                <mf-button label="Salvar" @click="clickButton"></mf-button>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </base-dialog>
</template>
<script>
import BaseDialog from '../atoms/BaseDialog.vue'
import VueCronEditorVuetify from '@charbytex/vue-cron-editor-vuetify'

export default {
  components: {
    BaseDialog,
    VueCronEditorVuetify
  },
  props: {
    /**
     * @model
     */
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: this.value,
      max_width: '1000',
      cronExpression: '*/1 * * * *',
      visibleTabs: ['minutes', 'hourly', 'daily', 'weekly', 'monthly', 'advanced']
    }
  },
  watch: {
    value() {
      this.dialog = this.value
    },
    dialog() {
      this.$emit('input', this.dialog)
    }
  },
  methods: {
    closeDialog() {
      this.$emit('input', false)
    },
    clickButton() {
      this.$emit('confirmAction', this.cronExpression)
    }
  }
}
</script>
